<template>
    <b-card v-if="delivery" :title="`${$t('edit_delivery')}`">
        <form @submit.prevent="updateDelivery" @keydown="form.onKeydown($event)" class="edit-form" enctype="multipart/form-data">
            <b-row>
                <b-col sm="6">
                    <div class="form-group">
                        <label for="name">{{ $t('name') }}<span class="text-danger">*</span></label>
                        <input type="text" name="name" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name" />
                    </div>
                    <div class="form-group">
                        <label for="description">{{ $t('description') }}<span class="text-danger">*</span></label>
                        <textarea class="form-control" v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                        <has-error :form="form" field="description" />
                    </div>
                    <div class="form-group">
                        <label for="status">{{ $t('status') }}<span class="text-danger">*</span></label>
                        <select v-model="form.status" class="form-control">
                            <option value="1">{{ $t('enabled') }}</option>
                            <option value="0">{{ $t('disabled') }}</option>
                        </select>
                        <has-error :form="form" field="status" />
                    </div>
                    <span v-if="delivery.slug === 'nova-posta'">
                        <div class="form-group">
                            <label for="api_key">{{ $t('api_key') }} (розділяти ,)</label>
                            <textarea class="form-control" v-model="form.api_keys" :class="{ 'is-invalid': form.errors.has('api_keys') }"></textarea>
                            <has-error :form="form" field="api_keys" />
                        </div>
                        <div class="form-group">
                            <label for="default_key">{{ $t('default_key') }}<span class="text-danger">*</span></label>
                            <select v-model="form.default_key" class="form-control" @change="getNPInfo">
                                <option v-for="(k, index) in apiKeys" :value="k" :key="index">{{ k }}</option>
                            </select>
                            <has-error :form="form" field="default_key" />
                        </div>
                        <div class="form-group">
                            <label for="default_key">{{ $t('sender') }}<span class="text-danger">*</span></label>
                            <select v-model="form.sender_ref" class="form-control">
                              <option value=""> -- </option>
                                <option v-for="(k, index) in senders" :value="k.Ref" :key="index">{{ k.LastName }} {{ k.FirstName }} {{ k.MiddleName }} - {{ k.Ref }}</option>
                            </select>
                            <has-error :form="form" field="default_key" />
                        </div>
                        <div class="form-group">
                            <label for="city">{{ $t('city_sender') }}</label>
                            <model-list-select
                                :list="cities"
                                option-value="ref"
                                option-text="name"
                                v-model="form.city_ref"
                                :placeholder="$t('nothing_selected')"
                                @searchchange="searchCity"></model-list-select>
                        </div>
                        <div class="form-group">
                            <label for="warehouse">{{ $t('warehouse_or_street') }}</label>
                            <model-list-select
                                :list="warehouses"
                                option-value="Ref"
                                option-text="Description"
                                v-model="form.warehouse_ref"
                                :placeholder="$t('nothing_selected')"
                                @searchchange="searchWarehouse"></model-list-select>
                                <small class="text-muted">{{ form.warehouse_ref }}</small>
                        </div>
                        <div class="form-group">
                            <label for="mode">{{ $t('mode') }}<span class="text-danger">*</span></label>
                            <select v-model="form.mode" class="form-control">
                                <option value="true">{{ $t('auto') }}</option>
                                <option value="false">{{ $t('manually') }}</option>
                            </select>
                            <has-error :form="form" field="mode" />
                        </div>
                    </span>
                    <span v-else-if="delivery.slug === 'ukr-posta'">
                        <div class="form-group">
                            <label for="counterparty_uuid">{{ $t('counterparty_uuid') }}<span class="text-danger">*</span></label>
                            <input type="text" name="counterparty_uuid" class="form-control" v-model="form.counterparty_uuid" :class="{ 'is-invalid': form.errors.has('counterparty_uuid') }">
                            <has-error :form="form" field="counterparty_uuid" />
                        </div>
                        <div class="form-group">
                            <label for="counterparty_token">{{ $t('counterparty_token') }}<span class="text-danger">*</span></label>
                            <input type="text" name="counterparty_token" class="form-control" v-model="form.counterparty_token" :class="{ 'is-invalid': form.errors.has('counterparty_token') }">
                            <has-error :form="form" field="counterparty_token" />
                        </div>
                        <div class="form-group">
                            <label for="bearer_ecom">{{ $t('bearer_ecom') }}<span class="text-danger">*</span></label>
                            <input type="text" name="bearer_ecom" class="form-control" v-model="form.bearer_ecom" :class="{ 'is-invalid': form.errors.has('bearer_ecom') }">
                            <has-error :form="form" field="bearer_ecom" />
                        </div>
                        <div class="form-group">
                            <label for="bearer_status_tracking">{{ $t('bearer_status_tracking') }}<span class="text-danger">*</span></label>
                            <input type="text" name="bearer_status_tracking" class="form-control" v-model="form.bearer_status_tracking" :class="{ 'is-invalid': form.errors.has('bearer_status_tracking') }">
                            <has-error :form="form" field="bearer_status_tracking" />
                        </div>
                        <div class="form-group">
                            <label for="status_mode">{{ $t('status_mode') }}<span class="text-danger">*</span></label>
                            <select v-model="form.status_mode" class="form-control">
                                <option value="sandbox">Sandbox (testing)</option>
                                <option value="production">Production</option>
                            </select>
                            <has-error :form="form" field="status_mode" />
                        </div>
                        <div class="form-group">
                            <label for="mode">{{ $t('mode') }}<span class="text-danger">*</span></label>
                            <select v-model="form.mode" class="form-control">
                                <option value="true">{{ $t('auto') }}</option>
                                <option value="false">{{ $t('manually') }}</option>
                            </select>
                            <has-error :form="form" field="mode" />
                        </div>
                    </span>
                    <div class="form-group">
                        <label for="mode">{{ $t('default_payment') }}<span class="text-danger">*</span></label>
                        <select v-model="form.default_payment" class="form-control">
                            <option v-for="(item, index) in payments" :key="index" :value="item.id">{{ item.name }}</option>
                        </select>
                        <has-error :form="form" field="default_payment" />
                    </div>
                </b-col>
                <b-col sm="6">
                    <UPRecipient v-if="delivery.slug === 'ukr-posta'" :delivery="delivery" />
                    <NPContragentInfo v-if="delivery.slug === 'nova-posta'" :contragentInfo="contragentData"/>
                </b-col>
            </b-row>
            <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('edit') }}</button>
        </form>
    </b-card>
</template>
<script>
import UPRecipient from './provider/ukrposhta/recipient'
import NPContragentInfo from './provider/novaposhta/contragentInfo'
import Form from 'vform'
import axios from 'axios'
import { ModelListSelect } from 'vue-search-select'

export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('edit') }
    },
    components: {
        ModelListSelect,
        UPRecipient,
        NPContragentInfo
    },
    data: () => ({
        form: new Form({
            name: '',
            description: '',
            status: '',
            api_keys: '',
            default_key: '',
            city_ref: '',
            warehouse_ref: '',
            sender_ref: '',
            mode: '',
            default_payment: '',
            counterparty_uuid: '',
            counterparty_token: '',
            bearer_ecom: '',
            bearer_status_tracking: '',
            status_mode: 'sandbox'
        }),
        selectedCity: '',
        cities: [],
        warehouses: [],
        delivery: null,
        contragentData: '',
        senders: [],
        payments: []
    }),
    mounted () {
        this.fetchData()
        this.fetchDataStatuses()
      if (this.form.default_key) {
        this.getConterpartyContacts()
      }
    },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        apiKeys () {
            if (this.form.api_keys) {
                return this.form.api_keys.split(',')
            }
        },
        contragentInfo () {
            if (this.contragentData) {
                return this.contragentData[0]
            }
        }
    },
    methods: {
        async updateDelivery () {
            this.form.busy = true
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const id = this.$route.params.id
            await this.form.put(apiUrl + 'deliveries/' + id).then(resp => {
                this.$router.push({ name: 'deliveries' })
            }).catch(error => {
                this.form.errors.set(error.response.data.error.errors)
            })
        },
        async fetchData () {
            const id = this.$route.params.id
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'deliveries/' + id)
            this.form.fill(data.data)
            this.delivery = data.data
            if (this.delivery.slug === 'nova-posta') {
                if (data.data.city_ref) {
                    this.getCity(data.data.city_ref)
                }
                await axios.post(apiUrl + 'deliveries/provider', { key: data.data.default_key }).then(resp => {
                    this.contragentData = resp.data.data
                })
                this.getConterpartyContacts()
            }
        },
        async fetchDataStatuses () {
            // const id = this.$route.params.id
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'payments')
            this.payments = data.data
        },
        async getNPInfo () {
            if (this.delivery.slug === 'nova-poshta') {
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                const { data } = await axios.post(apiUrl + 'deliveries/provider', { key: this.form.default_key })
                this.contragentData = data.data
            } else if (this.delivery.slug === 'ukr-posta') {
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                const { data } = await axios.post(apiUrl + 'deliveries/provider', { key: this.form.default_key })
                this.contragentData = data.data
            }
            this.getConterpartyContacts()
        },
        async getConterpartyContacts () {
          console.log(this.form.default_key)
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.post(apiUrl + 'deliveries/counterpartycontacts', { key: this.form.default_key })
            this.senders = data.data
        },
        async getCity (ref) {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await axios.post(apiUrl + 'city/ref', { city_ref: ref }).then(resp => {
                this.cities = resp.data.cities
            })
        },
        async searchCity (value) {
            if (value.length > 1) {
                this.searchCityText = value
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                await axios.post(apiUrl + 'city/search', { key: value }).then(resp => {
                    this.cities = resp.data.data
                    this.warehouses = []
                })
            }
        },
        async searchWarehouse (value) {
            this.$root.$refs.loading.start()
            if (this.form.city_ref) {
                if (value.length > 0) {
                    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                    const { data } = await axios.post(apiUrl + 'city/warehouse/cityref', { city: this.form.city_ref, key: value })
                    this.warehouses = data.data
                }
            }
            this.$root.$refs.loading.finish()
        },
        async updateAddress () {
            this.$root.$refs.loading.start()
            if (this.form.postcode.length >= 5) {
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                await axios.post(apiUrl + 'deliveries/sender/address', { postcode: this.form.postcode })
            }
        }
    }
}
</script>
